<template>
  <div id="expertise" class="w-100 section">
    <div class="container h-100">
      <div class="row">
        <div class="col col-12 col-md-6">
          <headline-text v-html="$t('expertise.title')" />
          <body-text class="body-text" v-html="$t('expertise.introduction')" />
          <ul
            class="list-group list-unstyled list-group-horizontal mt-4 certifications"
          >
            <li
              v-for="(certificate, index) in certifications"
              v-bind:key="index"
            >
              <social-icon
                v-bind:large="true"
                v-bind:icon="certificate.name"
                v-bind:url="certificate.url"
              />
            </li>
          </ul>
        </div>
        <div class="col col-12 offset-md-1 col-md-5 d-relative pe-0 text-end">
          <img
            src="../assets/img/ellipse-g.png"
            alt=""
            width="35"
            height="30"
            class="img"
          />

          <svg
            width="412"
            height="267"
            viewBox="0 0 412 267"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M180.554 161.02H180.352C177.635 160.268 103.955 117.46 95.5591 112.586C95.4412 112.523 95.3427 112.429 95.274 112.315C95.2052 112.2 95.1689 112.069 95.1689 111.935C95.1689 111.802 95.2052 111.67 95.274 111.556C95.3427 111.441 95.4412 111.347 95.5591 111.284L128.491 93.5539C128.665 93.4612 128.869 93.4411 129.059 93.4979C129.248 93.5547 129.407 93.684 129.502 93.8576C129.55 93.9437 129.58 94.0385 129.591 94.1365C129.601 94.2344 129.592 94.3335 129.564 94.4278C129.536 94.5222 129.489 94.6099 129.426 94.6859C129.363 94.7618 129.286 94.8244 129.199 94.8699L97.4087 111.877C120.847 125.501 178.329 158.735 180.699 159.487C180.854 159.549 180.985 159.662 181.069 159.808C181.153 159.953 181.187 160.122 181.163 160.289C181.14 160.455 181.062 160.609 180.941 160.726C180.82 160.843 180.663 160.916 180.496 160.933L180.554 161.02Z"
              fill="#D1D3D4"
            />
            <path
              d="M234.799 156.638C234.607 156.689 234.403 156.663 234.231 156.564C234.059 156.465 233.933 156.301 233.881 156.11C233.83 155.918 233.856 155.713 233.955 155.541C234.054 155.369 234.217 155.243 234.409 155.191C237.646 153.123 349.315 94.1468 354.069 91.6304C354.246 91.5388 354.451 91.5209 354.641 91.5805C354.83 91.6401 354.988 91.7725 355.081 91.9486C355.126 92.0349 355.153 92.1293 355.162 92.2263C355.17 92.3233 355.159 92.421 355.129 92.5138C355.1 92.6066 355.052 92.6926 354.989 92.7668C354.926 92.8411 354.849 92.9021 354.763 92.9465C353.607 93.5683 238.397 154.425 235.204 156.45C235.089 156.548 234.948 156.613 234.799 156.638Z"
              fill="#D1D3D4"
            />
            <path
              d="M354.416 126.701L297.902 94.06V78.8893L354.416 111.53V126.701Z"
              fill="white"
            />
            <path
              d="M354.416 127.424C354.29 127.425 354.167 127.395 354.055 127.337L297.541 94.6818C297.432 94.6187 297.341 94.5281 297.278 94.419C297.214 94.3099 297.18 94.1862 297.18 94.06V78.8892C297.181 78.7611 297.215 78.6355 297.278 78.5241C297.341 78.4127 297.432 78.3194 297.541 78.2529C297.651 78.1894 297.775 78.156 297.902 78.156C298.029 78.156 298.154 78.1894 298.263 78.2529L354.835 110.908C354.946 110.969 355.039 111.059 355.103 111.169C355.166 111.278 355.199 111.403 355.196 111.53V126.701C355.198 126.83 355.166 126.956 355.102 127.068C355.039 127.18 354.947 127.273 354.835 127.337C354.706 127.405 354.561 127.435 354.416 127.424ZM298.625 93.6405L353.694 125.457V111.95L298.625 80.133V93.6405Z"
              fill="#CCCCCC"
            />
            <path
              d="M410.93 94.1034L354.416 126.658V111.487L410.93 78.9182V94.1034Z"
              fill="white"
            />
            <path
              d="M354.416 127.381C354.288 127.383 354.162 127.348 354.055 127.279C353.945 127.216 353.855 127.126 353.791 127.017C353.728 126.908 353.694 126.784 353.693 126.658V111.487C353.694 111.359 353.728 111.233 353.791 111.122C353.855 111.01 353.945 110.917 354.055 110.851L410.569 78.2963C410.678 78.2329 410.803 78.1995 410.93 78.1995C411.057 78.1995 411.181 78.2329 411.291 78.2963C411.4 78.3594 411.491 78.45 411.554 78.5591C411.618 78.6682 411.652 78.792 411.652 78.9182V94.1034C411.652 94.2296 411.618 94.3534 411.554 94.4625C411.491 94.5716 411.4 94.6622 411.291 94.7253L354.835 127.279C354.711 127.36 354.563 127.396 354.416 127.381ZM355.138 111.906V125.37L410.207 93.6406V80.133L355.138 111.906Z"
              fill="#CCCCCC"
            />
            <path
              d="M354.416 111.545L297.902 78.8893L354.416 46.2339L410.93 78.8893L354.416 111.545Z"
              fill="white"
            />
            <path
              d="M354.416 112.268C354.289 112.263 354.165 112.229 354.055 112.167L297.541 79.5112C297.432 79.4481 297.341 79.3575 297.278 79.2484C297.214 79.1393 297.18 79.0155 297.18 78.8893C297.181 78.7612 297.215 78.6355 297.278 78.5242C297.341 78.4128 297.432 78.3195 297.541 78.253L354.055 45.612C354.165 45.5485 354.289 45.5151 354.416 45.5151C354.543 45.5151 354.668 45.5485 354.777 45.612L411.291 78.253C411.401 78.3195 411.491 78.4128 411.555 78.5242C411.618 78.6355 411.651 78.7612 411.653 78.8893C411.652 79.0155 411.618 79.1393 411.555 79.2484C411.491 79.3575 411.401 79.4481 411.291 79.5112L354.835 112.167C354.707 112.238 354.562 112.273 354.416 112.268ZM299.347 78.8893L354.416 110.706L409.485 78.8893L354.416 47.0727L299.347 78.8893Z"
              fill="#CCCCCC"
            />
            <path
              d="M354.416 103.75C354.283 103.753 354.153 103.717 354.04 103.648L312.294 79.5257C312.18 79.4616 312.084 79.3682 312.018 79.2551C311.952 79.142 311.917 79.0132 311.917 78.8821C311.917 78.751 311.952 78.6222 312.018 78.5091C312.084 78.396 312.18 78.3026 312.294 78.2386L354.04 54.1158C354.155 54.0512 354.284 54.0172 354.416 54.0172C354.547 54.0172 354.677 54.0512 354.791 54.1158L396.537 78.2386C396.652 78.3026 396.747 78.396 396.813 78.5091C396.879 78.6222 396.914 78.751 396.914 78.8821C396.914 79.0132 396.879 79.142 396.813 79.2551C396.747 79.3682 396.652 79.4616 396.537 79.5257L354.835 103.677C354.706 103.745 354.559 103.77 354.416 103.75ZM314.158 78.8893L354.416 102.144L394.673 78.8893L354.416 55.6198L314.158 78.8893Z"
              fill="#D1D3D4"
            />
            <path
              d="M140.513 229.743C140.381 229.746 140.25 229.711 140.137 229.642L44.6517 172.387C44.4997 172.277 44.3943 172.113 44.3563 171.93C44.3184 171.746 44.3506 171.554 44.4466 171.393C44.5427 171.231 44.6956 171.112 44.8752 171.057C45.0549 171.003 45.2484 171.018 45.4176 171.1L140.542 228.138L170.988 212.143C171.163 212.05 171.367 212.03 171.556 212.087C171.745 212.144 171.905 212.273 172 212.447C172.091 212.623 172.109 212.829 172.049 213.019C171.99 213.208 171.858 213.367 171.682 213.459L140.86 229.729C140.747 229.762 140.628 229.767 140.513 229.743Z"
              fill="#D1D3D4"
            />
            <path
              d="M243.917 177.434C243.737 177.449 243.559 177.4 243.413 177.295C243.267 177.189 243.163 177.035 243.121 176.86C243.079 176.685 243.102 176.501 243.184 176.341C243.266 176.181 243.403 176.055 243.57 175.988L305.921 143.101C306.03 143.04 306.151 143.008 306.276 143.008C306.4 143.008 306.521 143.04 306.63 143.101L366.395 176.06C366.48 176.108 366.555 176.171 366.616 176.248C366.677 176.325 366.722 176.412 366.749 176.506C366.776 176.6 366.784 176.699 366.772 176.796C366.761 176.893 366.731 176.987 366.684 177.072C366.636 177.158 366.572 177.233 366.496 177.294C366.419 177.355 366.332 177.4 366.238 177.427C366.144 177.454 366.046 177.462 365.949 177.45C365.852 177.439 365.758 177.409 365.672 177.362L306.225 144.677L244.263 177.434C244.149 177.46 244.031 177.46 243.917 177.434Z"
              fill="#D1D3D4"
            />
            <path
              d="M366.077 182.597C371.711 182.597 376.278 179.962 376.278 176.711C376.278 173.46 371.711 170.825 366.077 170.825C360.442 170.825 355.875 173.46 355.875 176.711C355.875 179.962 360.442 182.597 366.077 182.597Z"
              fill="white"
            />
            <path
              d="M366.077 183.335C363.435 183.391 360.824 182.768 358.491 181.527C357.507 181.163 356.659 180.507 356.059 179.645C355.46 178.784 355.139 177.76 355.139 176.711C355.139 175.661 355.46 174.637 356.059 173.776C356.659 172.915 357.507 172.259 358.491 171.895C360.842 170.704 363.441 170.084 366.077 170.084C368.713 170.084 371.311 170.704 373.663 171.895C374.647 172.259 375.495 172.915 376.095 173.776C376.694 174.637 377.015 175.661 377.015 176.711C377.015 177.76 376.694 178.784 376.095 179.645C375.495 180.507 374.647 181.163 373.663 181.527C371.33 182.768 368.718 183.391 366.077 183.335ZM366.077 171.562C363.696 171.51 361.342 172.073 359.242 173.197C358.484 173.422 357.818 173.886 357.345 174.521C356.872 175.156 356.616 175.926 356.616 176.718C356.616 177.51 356.872 178.281 357.345 178.915C357.818 179.55 358.484 180.014 359.242 180.24C361.362 181.309 363.703 181.866 366.077 181.866C368.451 181.866 370.792 181.309 372.912 180.24C373.67 180.014 374.336 179.55 374.809 178.915C375.282 178.281 375.538 177.51 375.538 176.718C375.538 175.926 375.282 175.156 374.809 174.521C374.336 173.886 373.67 173.422 372.912 173.197C370.812 172.073 368.458 171.51 366.077 171.562Z"
              fill="#D1D3D4"
            />
            <path
              d="M132.218 98.2541C137.853 98.2541 142.42 95.6188 142.42 92.368C142.42 89.1172 137.853 86.4819 132.218 86.4819C126.584 86.4819 122.017 89.1172 122.017 92.368C122.017 95.6188 126.584 98.2541 132.218 98.2541Z"
              fill="white"
            />
            <path
              d="M132.219 98.9916C129.58 99.0446 126.973 98.417 124.647 97.1693C123.662 96.8091 122.811 96.1547 122.21 95.2946C121.609 94.4344 121.287 93.4103 121.287 92.3607C121.287 91.3112 121.609 90.287 122.21 89.4268C122.811 88.5667 123.662 87.9123 124.647 87.5521C126.996 86.3614 129.593 85.741 132.226 85.741C134.859 85.741 137.456 86.3614 139.805 87.5521C140.79 87.9123 141.64 88.5667 142.241 89.4268C142.842 90.287 143.165 91.3112 143.165 92.3607C143.165 93.4103 142.842 94.4344 142.241 95.2946C141.64 96.1547 140.79 96.8091 139.805 97.1693C137.474 98.417 134.862 99.0445 132.219 98.9916ZM132.219 87.205C129.839 87.1619 127.487 87.7242 125.384 88.8392C124.625 89.0645 123.96 89.5291 123.487 90.1637C123.014 90.7982 122.758 91.5689 122.758 92.3607C122.758 93.1525 123.014 93.9232 123.487 94.5578C123.96 95.1923 124.625 95.6569 125.384 95.8822C127.504 96.9595 129.848 97.5209 132.226 97.5209C134.604 97.5209 136.948 96.9595 139.068 95.8822C139.826 95.6569 140.492 95.1923 140.965 94.5578C141.438 93.9232 141.694 93.1525 141.694 92.3607C141.694 91.5689 141.438 90.7982 140.965 90.1637C140.492 89.5291 139.826 89.0645 139.068 88.8392C136.96 87.7224 134.603 87.1601 132.219 87.205Z"
              fill="#D1D3D4"
            />
            <path
              d="M49.998 202.309L1.07031 174.05V160.904L49.998 189.163V202.309Z"
              fill="white"
            />
            <path
              d="M49.9401 203.032C49.813 203.029 49.6887 202.994 49.5788 202.931L0.708906 174.672C0.601575 174.607 0.512275 174.515 0.44917 174.407C0.386064 174.298 0.351165 174.175 0.347656 174.05V160.904C0.348417 160.778 0.382162 160.654 0.445537 160.545C0.508912 160.436 0.599709 160.345 0.708906 160.282C0.81874 160.218 0.943331 160.185 1.07016 160.185C1.19698 160.185 1.32157 160.218 1.43141 160.282L50.3591 188.541C50.4683 188.604 50.5591 188.695 50.6225 188.804C50.6859 188.913 50.7196 189.037 50.7204 189.163V202.309C50.7196 202.435 50.6859 202.559 50.6225 202.668C50.5591 202.777 50.4683 202.868 50.3591 202.931C50.2304 203 50.0861 203.035 49.9401 203.032ZM1.79266 173.63L49.2754 201.108V189.582L1.79266 162.104V173.63Z"
              fill="#CCCCCC"
            />
            <path
              d="M98.9113 174.079L49.998 202.265V189.119L98.9113 160.933V174.079Z"
              fill="white"
            />
            <path
              d="M49.9404 202.988C49.8124 202.993 49.6863 202.957 49.5791 202.887C49.4679 202.827 49.3754 202.736 49.3117 202.627C49.2481 202.517 49.2156 202.392 49.2179 202.265V189.119C49.2186 188.993 49.2524 188.869 49.3158 188.76C49.3791 188.651 49.4699 188.561 49.5791 188.497L98.4924 160.311C98.6022 160.247 98.7268 160.214 98.8536 160.214C98.9805 160.214 99.105 160.247 99.2149 160.311C99.3261 160.372 99.4186 160.462 99.4823 160.571C99.546 160.681 99.5784 160.806 99.5761 160.933V174.079C99.5784 174.206 99.546 174.331 99.4823 174.44C99.4186 174.55 99.3261 174.64 99.2149 174.701L50.3594 202.887C50.2344 202.966 50.0873 203.001 49.9404 202.988ZM50.6629 189.539V201.007L98.1311 173.659V162.191L50.6629 189.539Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.998 189.177L1.07031 160.904L49.998 132.645L98.9112 160.904L49.998 189.177Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.9401 189.9C49.813 189.898 49.6887 189.863 49.5788 189.799L0.708918 161.526C0.598728 161.464 0.507116 161.373 0.443592 161.264C0.380067 161.155 0.346947 161.03 0.347668 160.904C0.348429 160.778 0.382174 160.654 0.445549 160.545C0.508923 160.436 0.599721 160.345 0.708918 160.282L49.6366 132.008C49.7464 131.945 49.871 131.912 49.9979 131.912C50.1247 131.912 50.2493 131.945 50.3591 132.008L99.2724 160.282C99.3836 160.343 99.4761 160.433 99.5397 160.542C99.6034 160.652 99.6359 160.777 99.6336 160.904C99.6375 161.031 99.6058 161.157 99.5419 161.266C99.4781 161.376 99.3847 161.466 99.2724 161.526L50.3591 189.799C50.2304 189.868 50.0861 189.903 49.9401 189.9ZM2.51517 160.904L49.9979 188.382L97.4661 160.904L49.9401 133.469L2.51517 160.904Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.9977 181.787L13.8438 160.904L49.9977 140.021L86.1371 160.904L49.9977 181.787Z"
              fill="#D1D3D4"
            />
            <path
              d="M49.9405 182.539C49.8092 182.535 49.6806 182.5 49.5648 182.438L13.4832 161.526C13.368 161.461 13.2724 161.366 13.2063 161.252C13.1402 161.137 13.106 161.007 13.1075 160.875C13.1103 160.743 13.1463 160.615 13.2119 160.501C13.2776 160.387 13.3709 160.292 13.4832 160.224L49.6082 139.341C49.7206 139.277 49.8476 139.244 49.9766 139.244C50.1057 139.244 50.2326 139.277 50.3451 139.341L86.4701 160.224C86.5806 160.293 86.6718 160.389 86.735 160.503C86.7982 160.616 86.8314 160.745 86.8314 160.875C86.8358 161.006 86.8045 161.136 86.7409 161.25C86.6772 161.365 86.5836 161.46 86.4701 161.526L50.3451 182.409C50.2245 182.488 50.0847 182.533 49.9405 182.539ZM15.2605 160.846L49.9405 180.876L84.6205 160.846L49.9405 140.888L15.2605 160.846Z"
              fill="white"
            />
            <path
              d="M49.998 182.163L1.07031 153.904V140.773L49.998 169.032V182.163Z"
              fill="white"
            />
            <path
              d="M49.9401 182.886C49.8142 182.889 49.6899 182.859 49.5788 182.799L0.708906 154.526C0.599709 154.463 0.508912 154.372 0.445537 154.263C0.382162 154.154 0.348417 154.03 0.347656 153.904V140.773C0.348744 140.644 0.382498 140.519 0.445719 140.407C0.50894 140.296 0.599532 140.203 0.708906 140.136C0.81874 140.073 0.943331 140.039 1.07016 140.039C1.19698 140.039 1.32157 140.073 1.43141 140.136L50.3591 168.41C50.4693 168.472 50.5609 168.562 50.6244 168.671C50.688 168.781 50.7211 168.905 50.7204 169.031V182.163C50.7193 182.291 50.6855 182.417 50.6223 182.528C50.5591 182.64 50.4685 182.733 50.3591 182.799C50.2295 182.865 50.085 182.895 49.9401 182.886ZM1.79266 153.485L49.2754 180.963V169.494L1.79266 142.016V153.485Z"
              fill="#CCCCCC"
            />
            <path
              d="M98.9113 153.933L49.998 182.12V168.988L98.9113 140.802V153.933Z"
              fill="white"
            />
            <path
              d="M49.9404 182.843C49.8145 182.845 49.6902 182.815 49.5791 182.756C49.4678 182.692 49.3756 182.599 49.3121 182.487C49.2486 182.375 49.2161 182.248 49.2179 182.12V168.988C49.2156 168.861 49.2481 168.736 49.3117 168.627C49.3754 168.517 49.4679 168.427 49.5791 168.366L98.4924 140.18C98.6022 140.116 98.7268 140.083 98.8536 140.083C98.9805 140.083 99.105 140.116 99.2149 140.18C99.3261 140.24 99.4186 140.33 99.4823 140.44C99.546 140.55 99.5784 140.675 99.5761 140.801V153.933C99.5779 154.062 99.5454 154.189 99.4819 154.3C99.4184 154.412 99.3262 154.505 99.2149 154.569L50.3594 182.756C50.2298 182.821 50.0853 182.851 49.9404 182.843ZM50.6629 169.408V180.876L98.1311 153.514V142.045L50.6629 169.408Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.998 169.032L1.07031 140.773L49.998 112.499L98.9112 140.773L49.998 169.032Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.94 169.755C49.8142 169.757 49.6898 169.727 49.5788 169.668L0.708906 141.394C0.599709 141.331 0.508912 141.241 0.445537 141.132C0.382162 141.023 0.348417 140.899 0.347656 140.773C0.348744 140.644 0.382498 140.519 0.445719 140.407C0.50894 140.296 0.599532 140.203 0.708906 140.136L49.6366 111.877C49.7464 111.814 49.871 111.78 49.9978 111.78C50.1247 111.78 50.2493 111.814 50.3591 111.877L99.2723 140.136C99.3837 140.201 99.4758 140.293 99.5393 140.405C99.6028 140.517 99.6354 140.644 99.6336 140.773C99.6359 140.899 99.6034 141.024 99.5397 141.134C99.476 141.244 99.3836 141.334 99.2723 141.394L50.3591 169.668C50.2295 169.733 50.0849 169.763 49.94 169.755ZM2.45735 140.83L49.94 168.308L97.4083 140.83L49.94 113.338L2.45735 140.83Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.9977 161.656L13.8438 140.773L49.9977 119.889L86.1371 140.773L49.9977 161.656Z"
              fill="#D1D3D4"
            />
            <path
              d="M49.9401 162.393C49.8078 162.396 49.6774 162.361 49.5644 162.292L13.4394 141.409C13.3251 141.345 13.2299 141.251 13.1637 141.138C13.0974 141.025 13.0625 140.896 13.0625 140.765C13.0625 140.634 13.0974 140.505 13.1637 140.392C13.2299 140.279 13.3251 140.186 13.4394 140.122L49.5644 119.238C49.6769 119.175 49.8038 119.142 49.9329 119.142C50.062 119.142 50.1889 119.175 50.3014 119.238L86.4264 140.122C86.5371 140.189 86.6286 140.283 86.6921 140.396C86.7557 140.509 86.789 140.636 86.789 140.765C86.789 140.895 86.7557 141.022 86.6921 141.135C86.6286 141.247 86.5371 141.342 86.4264 141.409L50.3014 162.292C50.1935 162.361 50.0679 162.396 49.9401 162.393ZM15.2601 140.773L49.9401 160.788L84.6201 140.773L49.9401 120.743L15.2601 140.773Z"
              fill="white"
            />
            <path
              d="M49.998 162.032L1.07031 133.773V120.627L49.998 148.886V162.032Z"
              fill="white"
            />
            <path
              d="M49.9401 162.755C49.8121 162.759 49.6859 162.724 49.5788 162.654L0.708906 134.395C0.599709 134.332 0.508912 134.241 0.445537 134.132C0.382162 134.023 0.348417 133.899 0.347656 133.773V120.627C0.348417 120.501 0.382162 120.377 0.445537 120.268C0.508912 120.159 0.599709 120.068 0.708906 120.005C0.81874 119.941 0.943331 119.908 1.07016 119.908C1.19698 119.908 1.32157 119.941 1.43141 120.005L50.3591 148.264C50.4683 148.327 50.5591 148.418 50.6225 148.527C50.6859 148.636 50.7196 148.76 50.7204 148.886V162.032C50.7196 162.158 50.6859 162.282 50.6225 162.391C50.5591 162.5 50.4683 162.591 50.3591 162.654C50.2341 162.732 50.087 162.768 49.9401 162.755ZM1.79266 133.353L49.2754 160.831V149.363L1.79266 121.885V133.353Z"
              fill="#CCCCCC"
            />
            <path
              d="M98.9113 133.802L49.998 161.988V148.857L98.9113 120.67V133.802Z"
              fill="white"
            />
            <path
              d="M49.9404 162.712C49.8124 162.716 49.6863 162.68 49.5791 162.61C49.4679 162.55 49.3754 162.459 49.3117 162.35C49.2481 162.24 49.2156 162.115 49.2179 161.988V148.857C49.2161 148.728 49.2486 148.601 49.3121 148.49C49.3756 148.378 49.4678 148.285 49.5791 148.221L98.4924 120.034C98.6019 119.971 98.7261 119.937 98.8526 119.937C98.979 119.937 99.1033 119.97 99.213 120.033C99.3227 120.096 99.414 120.186 99.4777 120.296C99.5414 120.405 99.5754 120.529 99.5761 120.656V133.802C99.5784 133.929 99.546 134.054 99.4823 134.163C99.4186 134.273 99.3261 134.363 99.2149 134.424L50.3594 162.61C50.2344 162.689 50.0873 162.724 49.9404 162.712ZM50.6629 149.262V160.73L98.1311 133.382V121.914L50.6629 149.262Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.998 148.9L1.07031 120.627L49.998 92.3679L98.9112 120.627L49.998 148.9Z"
              fill="white"
            />
            <path
              d="M49.94 149.623C49.8121 149.628 49.6859 149.592 49.5788 149.522L0.708906 121.263C0.599532 121.197 0.50894 121.103 0.445719 120.992C0.382498 120.881 0.348744 120.755 0.347656 120.627C0.348417 120.501 0.382162 120.377 0.445537 120.268C0.508912 120.159 0.599709 120.068 0.708906 120.005L49.6366 91.7316C49.7464 91.6682 49.871 91.6348 49.9978 91.6348C50.1247 91.6348 50.2493 91.6682 50.3591 91.7316L99.2723 120.005C99.3836 120.066 99.476 120.156 99.5397 120.265C99.6034 120.375 99.6359 120.5 99.6336 120.627C99.6354 120.756 99.6028 120.882 99.5393 120.994C99.4758 121.106 99.3837 121.199 99.2723 121.263L50.3591 149.522C50.2341 149.601 50.087 149.636 49.94 149.623ZM2.51516 120.627L49.9978 148.105L97.4661 120.627L49.94 93.1923L2.51516 120.627Z"
              fill="#CCCCCC"
            />
            <path
              d="M49.9401 142.262C49.8078 142.265 49.6774 142.23 49.5644 142.161L13.4394 121.278C13.3251 121.214 13.2299 121.12 13.1637 121.007C13.0974 120.894 13.0625 120.765 13.0625 120.634C13.0625 120.503 13.0974 120.374 13.1637 120.261C13.2299 120.148 13.3251 120.055 13.4394 119.99L49.5644 99.0927C49.6769 99.0294 49.8038 98.9961 49.9329 98.9961C50.062 98.9961 50.1889 99.0294 50.3014 99.0927L86.4264 119.99C86.5371 120.057 86.6286 120.152 86.6921 120.265C86.7557 120.377 86.789 120.505 86.789 120.634C86.789 120.763 86.7557 120.891 86.6921 121.003C86.6286 121.116 86.5371 121.211 86.4264 121.278L50.3014 142.161C50.1935 142.229 50.0679 142.265 49.9401 142.262ZM15.2601 120.569L49.9401 140.599L84.6201 120.569L49.9401 100.611L15.2601 120.569Z"
              fill="#D1D3D4"
            />
            <path
              d="M17.8366 137.604C18.3549 137.305 18.3546 136.333 17.8359 135.434C17.3172 134.534 16.4764 134.048 15.9581 134.348C15.4397 134.647 15.4401 135.619 15.9588 136.518C16.4775 137.418 17.3182 137.904 17.8366 137.604Z"
              fill="#D1D3D4"
            />
            <path
              d="M12.4147 134.46C12.9331 134.16 12.9327 133.188 12.414 132.289C11.8953 131.39 11.0546 130.904 10.5362 131.203C10.0179 131.503 10.0182 132.475 10.5369 133.374C11.0556 134.273 11.8964 134.759 12.4147 134.46Z"
              fill="#D1D3D4"
            />
            <path
              d="M6.99186 131.33C7.51021 131.03 7.5099 130.058 6.99117 129.159C6.47244 128.26 5.63171 127.774 5.11336 128.073C4.59501 128.373 4.59532 129.345 5.11406 130.244C5.63279 131.143 6.47351 131.629 6.99186 131.33Z"
              fill="#D1D3D4"
            />
            <path
              d="M320.01 99.7348C320.611 99.3874 320.614 98.2646 320.015 97.2271C319.417 96.1895 318.444 95.63 317.843 95.9775C317.241 96.3249 317.239 97.4477 317.838 98.4853C318.436 99.5228 319.409 100.082 320.01 99.7348Z"
              fill="#D1D3D4"
            />
            <path
              d="M313.754 96.1192C314.356 95.7718 314.358 94.649 313.759 93.6115C313.161 92.5739 312.188 92.0144 311.587 92.3619C310.985 92.7093 310.983 93.8321 311.582 94.8697C312.18 95.9072 313.153 96.4667 313.754 96.1192Z"
              fill="#D1D3D4"
            />
            <path
              d="M309.025 93.3373C309.629 92.9898 309.633 91.8632 309.035 90.8209C308.436 89.7785 307.461 89.2152 306.857 89.5627C306.253 89.9101 306.249 91.0367 306.847 92.0791C307.446 93.1214 308.421 93.6847 309.025 93.3373Z"
              fill="#D1D3D4"
            />
            <path
              d="M302.815 242.918C302.683 242.921 302.552 242.886 302.439 242.817L246.301 209.713C246.216 209.665 246.142 209.601 246.082 209.524C246.022 209.447 245.978 209.359 245.952 209.265C245.927 209.17 245.92 209.072 245.933 208.975C245.946 208.879 245.977 208.785 246.026 208.701C246.129 208.531 246.293 208.408 246.484 208.357C246.675 208.306 246.879 208.33 247.052 208.426L303.191 241.53C303.382 241.58 303.546 241.704 303.646 241.875C303.747 242.046 303.775 242.249 303.725 242.441C303.675 242.633 303.551 242.797 303.381 242.897C303.21 242.998 303.006 243.026 302.815 242.976V242.918Z"
              fill="#D1D3D4"
            />
            <path
              d="M284.203 238.175V247.647C284.203 252.261 287.252 256.889 293.35 260.403C305.546 267.46 325.313 267.46 337.509 260.403C343.607 256.889 346.656 252.261 346.656 247.647V238.175H284.203Z"
              fill="white"
            />
            <path
              d="M315.429 266.361C307.616 266.519 299.892 264.677 292.989 261.01C286.862 257.467 283.48 252.709 283.48 247.619V238.175C283.48 237.983 283.557 237.799 283.692 237.663C283.828 237.528 284.011 237.452 284.203 237.452H346.656C346.847 237.452 347.031 237.528 347.167 237.663C347.302 237.799 347.378 237.983 347.378 238.175V247.647C347.378 252.738 343.997 257.496 337.87 261.039C330.964 264.695 323.24 266.527 315.429 266.361ZM284.925 238.883V247.633C284.925 252.189 288.047 256.498 293.711 259.767C305.69 266.694 325.169 266.694 337.148 259.767C342.812 256.498 345.933 252.189 345.933 247.633V238.883H284.925Z"
              fill="#CCCCCC"
            />
            <path
              d="M315.43 254.878C332.675 254.878 346.656 246.798 346.656 236.83C346.656 226.862 332.675 218.781 315.43 218.781C298.184 218.781 284.203 226.862 284.203 236.83C284.203 246.798 298.184 254.878 315.43 254.878Z"
              fill="white"
            />
            <path
              d="M315.429 255.529C307.616 255.687 299.892 253.845 292.989 250.178C286.862 246.635 283.48 241.877 283.48 236.786C283.48 231.696 286.862 226.938 292.989 223.394C305.358 216.25 325.501 216.25 337.87 223.394C343.997 226.938 347.378 231.696 347.378 236.786C347.378 241.877 343.997 246.635 337.87 250.178C330.967 253.844 323.243 255.686 315.429 255.529ZM315.429 219.461C307.881 219.321 300.422 221.108 293.754 224.653C288.09 227.921 284.969 232.231 284.969 236.786C284.969 241.342 288.09 245.652 293.754 248.92C305.733 255.847 325.212 255.833 337.191 248.92C342.856 245.652 345.977 241.342 345.977 236.786C345.977 232.231 342.856 227.921 337.191 224.653C330.494 221.108 323.003 219.336 315.429 219.504V219.461Z"
              fill="#CCCCCC"
            />
            <path
              d="M315.43 249.585C327.624 249.585 337.51 243.874 337.51 236.83C337.51 229.785 327.624 224.074 315.43 224.074C303.236 224.074 293.351 229.785 293.351 236.83C293.351 243.874 303.236 249.585 315.43 249.585Z"
              fill="white"
            />
            <path
              d="M315.43 250.323C309.862 250.436 304.36 249.116 299.448 246.49C295.026 243.945 292.599 240.518 292.599 236.83C292.599 233.142 295.026 229.714 299.448 227.155C308.262 222.064 322.568 222.064 331.411 227.155C335.746 229.714 338.261 233.142 338.261 236.83C338.261 240.518 335.833 243.945 331.411 246.49C326.5 249.116 320.997 250.436 315.43 250.323ZM315.43 224.812C310.121 224.707 304.873 225.962 300.185 228.456C296.254 230.727 294.087 233.706 294.087 236.83C294.087 239.954 296.254 242.933 300.185 245.203C308.595 250.063 322.264 250.063 330.674 245.203C334.605 242.933 336.758 239.954 336.758 236.83C336.758 233.706 334.605 230.727 330.674 228.456C325.986 225.962 320.738 224.707 315.43 224.812Z"
              fill="#D1D3D4"
            />
            <path
              d="M205.278 242.253L117.898 191.78V168.308L205.278 218.781V242.253Z"
              fill="white"
            />
            <path
              d="M205.277 242.976C205.15 242.977 205.025 242.942 204.916 242.875L117.537 192.402C117.43 192.337 117.34 192.246 117.277 192.137C117.214 192.029 117.179 191.906 117.176 191.78V168.308C117.177 168.182 117.21 168.058 117.274 167.949C117.337 167.84 117.428 167.75 117.537 167.686C117.647 167.623 117.771 167.59 117.898 167.59C118.025 167.59 118.15 167.623 118.26 167.686L205.639 218.159C205.748 218.222 205.839 218.313 205.902 218.422C205.965 218.531 205.999 218.655 206 218.781V242.253C206.002 242.38 205.97 242.505 205.906 242.614C205.842 242.724 205.75 242.814 205.639 242.875C205.531 242.943 205.405 242.979 205.277 242.976ZM118.577 191.361L204.555 241.067V219.2L118.621 169.567L118.577 191.361Z"
              fill="#CCCCCC"
            />
            <path
              d="M292.656 191.838L205.277 242.181V218.709L292.656 168.366V191.838Z"
              fill="white"
            />
            <path
              d="M205.277 242.904C205.15 242.905 205.024 242.87 204.916 242.803C204.807 242.739 204.716 242.649 204.653 242.54C204.589 242.431 204.555 242.307 204.555 242.181V218.709C204.555 218.583 204.589 218.459 204.653 218.35C204.716 218.241 204.807 218.15 204.916 218.087L292.295 167.744C292.405 167.681 292.53 167.647 292.656 167.647C292.783 167.647 292.908 167.681 293.018 167.744C293.129 167.805 293.221 167.895 293.285 168.005C293.349 168.114 293.381 168.239 293.379 168.366V191.896C293.378 192.022 293.344 192.146 293.281 192.255C293.218 192.364 293.127 192.455 293.018 192.518L205.638 242.803C205.531 242.871 205.405 242.906 205.277 242.904ZM206 219.128V240.923L291.934 191.419V169.61L206 219.128Z"
              fill="#CCCCCC"
            />
            <path
              d="M205.278 218.796L117.898 168.308L205.278 117.821L292.657 168.308L205.278 218.796Z"
              fill="white"
            />
            <path
              d="M205.278 219.519C205.152 219.518 205.029 219.488 204.917 219.432L117.537 168.93C117.426 168.869 117.333 168.779 117.268 168.67C117.203 168.56 117.169 168.436 117.169 168.308C117.169 168.181 117.203 168.056 117.268 167.947C117.333 167.837 117.426 167.747 117.537 167.686L204.917 117.185C205.029 117.128 205.152 117.098 205.278 117.098C205.403 117.098 205.527 117.128 205.639 117.185L293.018 167.686C293.13 167.747 293.223 167.837 293.288 167.947C293.353 168.056 293.387 168.181 293.387 168.308C293.387 168.436 293.353 168.56 293.288 168.67C293.223 168.779 293.13 168.869 293.018 168.93L205.639 219.374C205.533 219.452 205.409 219.502 205.278 219.519ZM119.3 168.308L205.278 217.928L291.255 168.308L205.278 118.646L119.3 168.308Z"
              fill="#CCCCCC"
            />
            <path
              d="M205.278 198.361C234.008 198.361 257.298 184.906 257.298 168.308C257.298 151.711 234.008 138.256 205.278 138.256C176.548 138.256 153.258 151.711 153.258 168.308C153.258 184.906 176.548 198.361 205.278 198.361Z"
              fill="white"
            />
            <path
              d="M205.277 199.127C191.825 199.127 178.372 196.235 168.126 190.247C157.881 184.26 152.521 176.653 152.521 168.352C152.521 160.051 158.055 152.27 168.126 146.456C188.617 134.612 221.953 134.612 242.428 146.456C252.543 152.241 258.049 160.051 258.049 168.352C258.049 176.653 252.5 184.434 242.428 190.247C232.357 196.061 218.73 199.127 205.277 199.127ZM205.277 139.037C192.099 139.037 178.906 141.93 168.863 147.714C159.283 153.253 154.009 160.571 154.009 168.323C154.009 176.075 159.283 183.392 168.863 188.931C188.949 200.501 221.606 200.501 241.691 188.931C251.272 183.392 256.546 176.075 256.546 168.323C256.546 160.571 251.272 153.253 241.691 147.714C231.649 141.901 218.47 138.994 205.277 138.994V139.037Z"
              fill="#D1D3D4"
            />
            <path
              d="M261.358 203.046L239.076 215.903V207.501L261.358 194.629V203.046Z"
              fill="white"
            />
            <path
              d="M239.076 216.655C238.944 216.658 238.814 216.623 238.701 216.554C238.587 216.488 238.493 216.393 238.43 216.279C238.366 216.164 238.335 216.034 238.339 215.903V207.501C238.337 207.372 238.37 207.245 238.434 207.133C238.497 207.021 238.589 206.929 238.701 206.864L260.982 193.993C261.092 193.922 261.22 193.884 261.351 193.884C261.482 193.884 261.61 193.922 261.719 193.993C261.832 194.057 261.927 194.149 261.993 194.261C262.059 194.372 262.094 194.5 262.095 194.629V203.046C262.094 203.176 262.059 203.303 261.993 203.415C261.927 203.527 261.832 203.619 261.719 203.683L239.452 216.554C239.339 216.623 239.209 216.658 239.076 216.655ZM239.828 207.978V214.659L260.607 202.656V195.974L239.828 207.978Z"
              fill="#D1D3D4"
            />
            <path
              d="M149.213 200.535C150.167 199.984 150.164 198.191 149.206 196.531C148.249 194.871 146.699 193.972 145.746 194.523C144.792 195.074 144.795 196.867 145.753 198.527C146.71 200.187 148.26 201.086 149.213 200.535Z"
              fill="#D1D3D4"
            />
            <path
              d="M139.216 194.771C140.17 194.22 140.167 192.428 139.209 190.767C138.252 189.107 136.702 188.208 135.748 188.759C134.795 189.311 134.798 191.103 135.755 192.763C136.713 194.423 138.263 195.322 139.216 194.771Z"
              fill="#D1D3D4"
            />
            <path
              d="M129.22 189.007C130.174 188.456 130.171 186.664 129.213 185.004C128.256 183.344 126.706 182.445 125.752 182.996C124.799 183.547 124.802 185.339 125.759 186.999C126.717 188.66 128.267 189.559 129.22 189.007Z"
              fill="#D1D3D4"
            />
            <g id="svg-top">
              <path
                d="M128.49 39.4222L205.653 0.75061L283.51 41.8663L206 92.3679L128.49 39.4222Z"
                fill="#FFE6B8"
              />
              <path
                d="M128.49 39.4222L205.653 147.338V92.3679L128.49 39.4222Z"
                fill="#FFF6E6"
              />
              <path
                d="M205.653 147.338L283.51 41.8663L205.653 92.3679V147.338Z"
                fill="#FFF0D4"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="row mt-5">
        <div
          class="col col-6 col-md-auto skill-list-wrapper"
          v-for="(skill, index) in skills"
          v-bind:key="index"
        >
          <label>{{ $t(skill.category) }}</label>
          <ul class="list-unstyled list-group mt-2 skill-list">
            <li
              v-for="(subskill, index2) in skill.skillList"
              v-bind:key="index2"
            >
              {{ subskill }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadlineText from "./HeadlineText.vue";
import BodyText from "./BodyText.vue";
import SocialIcon from "./SocialIcon.vue";
import { gsap } from "gsap";

export default {
  name: "expertise-section",
  data() {
    return {
      certifications: [
        {
          name: "provenexpert",
          url: "https://www.provenexpert.com/david-minkovski/",
        },
        { name: "psm", url: "https://www.scrum.org/user/823253" },
        {
          name: "aws",
          url: "https://www.credly.com/badges/77e951b0-6419-41fc-a794-3ec0087ef1c1/public_url",
        },
        {
          name: "cka",
          url: "https://www.credly.com/badges/275abdac-c53d-4aed-9e8e-c48c3f7e188e/public_url",
        },
        {
          name: "mongodb",
          url: "https://university.mongodb.com/course_completion/d49028aa-21aa-4d14-97f8-399969227b0d",
        },
        {
          name: "azure",
          url: "https://www.credly.com/badges/07d1d1a6-b06e-43ea-bccc-3592443c3332/public_url",
        },
        {
          name: "prosci",
          url: "https://www.credly.com/badges/cc47a6a5-f0b0-4aea-a6c2-184efad99436/public_url",
        },
      ],
      skills: [
        {
          category: "expertise.databases",
          skillList: ["PostgreSQL", "MariaDB", "MongoDB", "Firebase"],
        },
        {
          category: "expertise.devops",
          skillList: [
            "GitLab · Github · Jenkins",
            "Docker · Kubernetes",
            "Confluence · Jira",
            "Terraform · Bicep",
            "Microsoft Azure",
            "Monitoring · Logging",
          ],
        },
        {
          category: "expertise.backend",
          skillList: [
            "IntelliJ · Eclipse",
            "Java · Spring, Quarkus",
            "TypeScript · Express",
            "Go · Gin Gonic",
            "Rust · Actix",
          ],
        },
        {
          category: "expertise.frontend",
          skillList: [
            "Visual Studio Code",
            "TypeScript",
            "React · Redux",
            "Angular(JS) · Vue",
            "HTML · CSS · Sass",
            "Jest · Cypress",
            "Mocha · Chai",
            "Cucumber · Gherkin",
          ],
        },
        {
          category: "expertise.skills",
          skillList: [
            "Leadership · Teamwork",
            "Mentoring · Motivation",
            "Public Speaking",
            "Product Management"
          ],
        },
      ],
    };
  },
  mounted: function () {
    this.animate();
  },
  components: { HeadlineText, BodyText, SocialIcon },
  methods: {
    animate() {
      gsap.to("#svg-top", {
        keyframes: [
          { y: 20, duration: 1 },
          { y: 0, duration: 1 },
        ],
        ease: "Expo.easeInOut",
        repeat: -1,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped components>
.img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: -1;
}
svg {
  z-index: 1;
}

#expertise {
  background: url("../assets/img/bg-grey-mid.png") center center;
  background-size: cover;
}
ul li {
  margin-right: 30px;
}
img {
  height: 60px;
}
label {
  color: #777;
}
.skill-list {
  line-height: 40px;
  font-size: 18px;
}
@media screen and (max-width: 440px) {
  .body-text {
    margin-top: 25px;
  }
  .skill-list {
    font-size: 14px;
  }
  .skill-list-wrapper {
    margin-bottom: 15px;
  }
}
</style>
