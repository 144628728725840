import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import * as Vue from "vue";
import * as VueRouter from "vue-router";

import app from "./App.vue";
import Home from "./components/Home.vue";
import Imprint from "./components/Imprint.vue";
import Post from "./components/Post.vue";
import i18n from "./i18n";

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  cache,
  uri:
    "https://api-eu-central-1.graphcms.com/v2/ckzr5muqt0idp01z4e2n89uyp/master",
});
const apolloProvider = new createApolloProvider({
  defaultClient: apolloClient,
});
const routes = [
  { path: "/", component: Home },
  { path: "/blog/:id", component: Post },
  { path: "/imprint", component: Imprint },
];
const router = new VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

const vueApp = Vue.createApp(app);
vueApp.use(router);
vueApp.use(i18n);
vueApp.use(apolloProvider);
vueApp.mount("#app");
