<template>
  <div id="post" class="w-100">
    <div class="post-container">
      <h1 v-html="post.title"></h1>
      <h2 v-html="post.subtitle"></h2>
      <ul class="list-unstyled">
        <Tag class="tag" v-for="(tag, index) in post.tags" v-bind:key="index">
          {{ tag }}
        </Tag>
      </ul>
      <img v-bind:src="post.coverImage.url" class="post-image img-fluid" />
      <div class="container h-100 post-body">
        <div class="row">
          <div v-html="post.content.html" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Tag from "./Tag.vue";

export default {
  name: "post",
  apollo: {
    post: {
      query: gql`
        query MyPost($id: String) {
          post(where: { slug: $id }) {
            id
            title
            subtitle
            content {
              html
            }
            coverImage {
              height
              width
              url
            }
            tags
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        this.post = data.post;
        this.updateMetaTags();
      }
    },
  },
  watch: {
   'post.title'(newTitle) {
      document.title = newTitle || 'Blog';
      this.updateMetaTags();
    },
    'post.subtitle'() {
      this.updateMetaTags();
    },
    'post.coverImage.url'() {
      this.updateMetaTags();
    }
  },
  mounted() {
    // Set initial values
    document.title = this.post.title || 'Blog';
    this.updateMetaTags();
  },
  data() {
    return {
      id: this.$route.params.id,
      post: {},
    };
  },
  methods: {
    updateMetaTags() {
      document.title = this.post.title || 'Blog';
      
      this.updateMetaTag('description', this.post.subtitle);
      this.updateMetaTag('og:title', this.post.title);
      this.updateMetaTag('og:description', this.post.subtitle);
      this.updateMetaTag('og:image', this.post.coverImage.url);
      this.updateMetaTag('og:url', window.location.href);
      this.updateMetaTag('og:type', 'article');
      
      this.updateMetaTag('twitter:card', 'summary_large_image');
      this.updateMetaTag('twitter:title', this.post.title);
      this.updateMetaTag('twitter:description', this.post.subtitle);
      this.updateMetaTag('twitter:image', this.post.coverImage.url);
      this.updateMetaTag('twitter:url', window.location.href);
    },
    updateMetaTag(name, content) {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('name', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
      
      element = document.querySelector(`meta[property="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('property', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    }

  },
  components: { Tag },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
pre {
  border: 1px solid #E5E5E5;
  padding: 32px;
  background: #F9F9F9;
}

.post-body img {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}
.post-body h2 {
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 50px;
  margin-bottom: 15px;
}

.post-body h2,
.post-body h3,
.post-body h4 {
  font-size: 1.4rem;
  font-family: "Open Sans";
  font-weight: bold;
  color: #333;
  margin: 25px 0px;
}
.post-body h2 {
  font-size: 1.8rem;
}
.post-body h3 {
  font-size: 1.4rem;
}
.post-body h1 {
    margin: 25px 0px;
}

.post-body p {
  line-height: 1.8rem;
  margin-top: 2rem;
  margin-bottom:1rem;
}
.post-body li {
    font-weight: 400;
    margin-bottom: 15px;
}
.post-body li strong {
  line-height: 40px;
}
.post-body blockquote {
    font-style: italic;
    font-size: 1.1rem;
    padding: 0;
    padding-left: 15px;
    text-align: left;
    color: rgba(0, 0, 0, .68);
    border-left: 2px solid rgba(0, 0, 0, .68);
}
.post-body .blockquote-big blockquote {
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 50px;
  margin-bottom: 50px;
  border-left: 0;
}
.post-body p > code, .post-body div > code {
  background: rgba(0, 0, 0, .05);
  padding: 3px 4px;
  margin: 0 2px;
  color: rgba(0, 0, 0, .84);
  font-size:1rem;
}
</style>
<style scoped>
#post {
  background: #fff;
  padding-top: 150px;
  padding-bottom: 100px;
}
.post-image {
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.post-container {
  margin: auto;
  overflow: hidden;
  padding: 1rem;
}
@media screen and (min-width: 680px) {
  .post-container {
    width: 680px;
  }
}
img {
  width: 100% !important;
}
.post-body {
  padding: 0.5rem;
  margin-top: 50px;
  color: #333333;
}

h1 {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.011em;
}

h2 {
  color: rgba(109, 110, 110, 1);
  letter-spacing: 0;
  font-size: 24px;
  line-height: normal;
  font-weight: normal;
}

.tag {
  background: rgb(230, 230, 230);
  color: rgba(109, 110, 110, 1);
  font-size: 13px;
  border-radius: 3px;
  list-style-type: none;
  padding: 5px 10px;
  display: inline-block;
  margin: 4px 4px;
}
</style>
