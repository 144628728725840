<template>
  <div id="blog" class="w-100 section">
    <div class="container h-100">
      <div class="row">
        <div class="col col-12 col-md-6">
          <headline-text v-html="$t('blog.title')" />
          <body-text class="body-text" v-html="$t('blog.introduction')" />
          <div class="text-center w-100"></div>
        </div>
        <div id="posts" class="posts-wrapper">
          <div class="posts-container w-100 row d-flex flex-row">
            <div
              class="col col-12 col-md-3 d-flex justify-content-center align-items-center"
              v-for="(post, index) in posts"
              v-bind:key="index"
            >
              <div class="post">
                <div class="img-container">
                  <a :href="`/blog/${post.slug}`"
                    ><img v-bind:src="post.coverImage.url" class="img-fluid" />
                  </a>
                </div>
                <div class="title">
                  <a class="link" :href="`/blog/${post.slug}`" v-html="post.title"></a>
                </div>
                <div class="excerpt">
                  <p v-html="post.excerpt" />
                </div>
                <ul class="list-unstyled p-1 m-0 mt-1 tags-container">
                  <Tag
                    class="tag"
                    v-for="(tag, index) in post.tags"
                    v-bind:key="index"
                  >
                    {{ tag }}
                  </Tag>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadlineText from "./HeadlineText.vue";
import BodyText from "./BodyText.vue";
import Tag from "./Tag.vue";
import gql from "graphql-tag";

export default {
  name: "blog-section",
  apollo: {
    posts: gql`
      query MyQuery {
        posts(orderBy: date_DESC) {
          id
          content {
            html
          }
          coverImage {
            fileName
            url
            width
            height
          }
          slug
          tags
          title
          publishedAt
          author {
            name
          }
          excerpt
        }
      }
    `,
  },
  mounted: function () {},
  components: { HeadlineText, BodyText, Tag },
  methods: {
    animate() {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped components>
#blog {
  background: url("../assets/img/bg-grey-mid.png") center center;
  background-size: cover;
}
#posts {
  width: 100%;
  padding: 2rem;
  overflow-x: auto;
}
.posts-container {
  width: 100%;
}
.post {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 10px;
  margin-bottom: 50px;
}
img {
  width: 100%;
  height: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.excerpt {
  margin-top: 0px;
  padding: 0 0.5rem 0 0.5rem;
  font-size: 0.8rem;
  color: #868e96;
}
.excerpt p {
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
.link {
  margin: auto;
  text-decoration: none;
  color: #0984e3;
}
.link-container {
  justify-content: center;
}
.tags-container {
  max-height: 30px;
  overflow: hidden;
}
.tag {
  padding: 4px;
  font-size: 10px;
}
.img-container {
  max-height: 194px;
  overflow: hidden;
  position: relative;
}
.img-container h3 {
  color: #fff;
  font-size: 24px;
  margin: auto;
  text-align: center;
}
.title {
  font-size: .95rem;
  line-height: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin:0;
}
</style>
