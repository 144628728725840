export default {
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategy"])},
    "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I am David. <br />IT Consultant & Solution Architect."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I love turning tough tech problems into simple solutions <br /> and big ideas into real results."])}
  },
  "strategy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My strategy"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
    "analyseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First of all, it is essential to understand existing processes. Important process steps that are only known to the direct employee are often overlooked.<br/>A thorough analysis enables me to know the real scope of the overall processes."])},
    "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "conceptText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful business is created through innovative ideas, sustainable strategies and tailor-made IT solutions that optimally support companies in their individual challenges. I bring the right mix of technology expertise and a sound understanding of business models to the table."])},
    "digitize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitize"])},
    "digitizeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitization means realigning company processes with the help of latest technologies. This means on the one hand, that new tools have to be purchased and on the other hand, that the requirements placed on employees can change.<br/>Using agile methods we create an iterative and goal-oriented team effort."])}
  },
  "expertise": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My skills"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to my previous experience in various areas of IT and business management, I have a broad portfolio of knowledge and skills. I am fascinated by the versatility of the digital world. I am always looking for new exciting challenges that call for my solution-oriented thinking, my passion for creativity and design, and my enjoyment of teamwork."])},
    "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases"])},
    "devops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps & Cloud"])},
    "backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "backendAndDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend & DBs"])},
    "frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])},
    "focus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])}
  },
  "references": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of my clients"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of my projects were similar - all challenging and highly attractive. The privilege of working on so many different products enabled me to apply my knowledge and solve problems creatively."])}
  },
  "books": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From my bookshelf"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over the years, many books have helped me gain a solid understanding of tools, principles, and best practices related to technology and entrepreneurship. So I've made a small list of my favorite books that I can recommend to everyone. <br /> <br /> In addition, I had the great pleasure of writing my own book, which introduces 10 principles derived from Krav Maga for a successful leader."])}
  },
  "blog": {
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up for 'David the Techie'"])},
    "newsletterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to a magic place where laziness is redefined as efficiency, and every newsletter is a burst of tech inspiration, problem-solving hacks, and entrepreneurial spirit. Subscribe for your weekly dose of innovation and mind-freeing insights."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Blog"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can find exciting articles around Technologies, Project Management and IT-Architecture."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
  }
}